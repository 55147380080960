import { useAxios } from "./useAxios";
// Types
import type { Api } from "@types";

const BASE_URI = `/v0`;

/**
 * Refresh Reporting Data
 */
export const useRefreshReportingData = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        refreshReportingData: (countyIsoCode: string, countryName: string) =>
            fetch(
                {
                    method: "POST",
                    url: `${BASE_URI}/maintenence/refreshReportingData`,
                    data: {
                        countyIsoCode,
                    },
                },
                {
                    errorHandler: `Failed to refresh reporting data for ${countryName}`,
                    successHandler: `Reporting data is successfully refreshed for ${countryName}`,
                },
            ),
        error,
        loading,
        response,
    };
};
