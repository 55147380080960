// Icons
import AutoModeIcon from "@mui/icons-material/AutoMode";

// Libs
import { navigate } from "gatsby";
import { Box, Typography } from "@mui/material";
import clonedeep from "lodash.clonedeep";
import React, { useEffect, useMemo, useRef, Fragment } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowContent,
    FlowBody,
    FlowAside,
    FlowFooter,
    Dialog,
} from "@components";

// Hooks
import { useFindStep, useScanner, useUploadClaim } from "@hooks";

// Recoil
import {
    uploadClaimFileStepsState,
    claimContractSelectionState,
    claimDetailsState,
} from "@atoms";

// Selectors
import { uploadClaimProcessMapper } from "@selectors";

// Types
import type { Location } from "@types";

// Utils
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
}

/**
 * Create claim layout
 */
const ClaimProcessLayout = ({ location, children }: Props) => {
    /**
     * States
     */

    const [invalidFile, setFileValidity] = React.useState<
        { fileKey: string; fileName: string } | undefined
    >(undefined);

    const [failedFiles, setFailedFiles] = React.useState<
        {
            fileData: string;
            fileName: string;
            fileType: "DEFAULT" | "EXCEL";
            index: number;
        }[]
    >([]);

    // via this ref we can save the state of each page via stepper (higher order)
    const stepRef = useRef<any>();

    const retryCallbackRef =
        useRef<(shouldRetry: boolean) => void | null>(null);

    const {
        upload: uploadClaim,
        loading: { uploading, validating },
        validate: validateClaimFile,
        forceReset,
    } = useUploadClaim();

    const { scan, loading: scanning } = useScanner("EXCEL");

    //Steps hooks
    const { currentIndex: currentStepIndex, currentStep } = useFindStep(
        location,
        uploadClaimFileStepsState,
    );
    const [steps, setSteps] = useRecoilState(uploadClaimFileStepsState);

    const resetAtoms = useResetRecoilState(uploadClaimProcessMapper);

    /**
     * States
     */

    // Contract state
    const selectedContract = useRecoilValue(claimContractSelectionState);

    // Order state
    const claimDetails = useRecoilValue(claimDetailsState);

    /**
     * Reset stored atom
     */
    const reset = () => {
        resetAtoms();
    };

    const isFinished = useMemo(() => {
        return !!steps[steps.length - 1]?.isCompleted;
    }, [steps]);

    /**
     * Reset recoil state on refresh
     */
    useEffect(() => {
        if (
            currentStepIndex > 0 &&
            steps[currentStepIndex - 1].isPrepared === false
        ) {
            reset();
            navigate(`/process/process-details/`);
            return;
        }
    }, [currentStepIndex, location]);

    /**
     * Check if the request can be submitted
     */
    const canSubmit = useMemo(() => {
        if (currentStepIndex !== steps.length - 1) return false; //is not submit

        return !steps.find(
            (step, index) => index < currentStepIndex && !step.isCompleted,
        );
    }, [currentStepIndex, steps]);

    /**
     *  Reset on refresh the page
     */
    useEffect(() => {
        const handleUnload = (e: any) => {
            e.preventDefault();
            const dialogText =
                "You are about to leave the page. Your data will not be saved and thus will be lost."; // old browsers.
            e.returnValue = dialogText;
            return dialogText;
        };
        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", handleUnload);
            return function cleanup() {
                window.removeEventListener("beforeunload", handleUnload);
            };
        }
    }, []);

    /**
     * Upload handler
     */
    const onClaimUpload = (
        validateFirst: boolean,
        file: { fileKey: string; fileName: string },
        override?: boolean,
    ) => {
        forceReset();
        if (
            !file ||
            file.fileKey === "" ||
            file.fileName === "" ||
            !claimDetails?.periodFrom ||
            !claimDetails?.periodTo ||
            !claimDetails?.file ||
            !claimDetails.contractPartner.accountId ||
            (claimDetails?.isUkTeam &&
                (claimDetails?.claimCustomerReference === "" ||
                    claimDetails?.claimCustomerInfo === "" ||
                    claimDetails?.claimDocumentDate === "" ||
                    claimDetails?.claimDueDate === ""))
        )
            return;

        const mapper = {
            ...file,
            periodFrom: claimDetails.periodFrom,
            periodTo: claimDetails.periodTo,
            contractPartnerId: claimDetails.contractPartner.accountId,
            contractId: selectedContract.id,
            claimCustomerReference: claimDetails?.isUkTeam
                ? claimDetails?.claimCustomerReference
                : undefined,
            claimCustomerInfo: claimDetails.isUkTeam
                ? claimDetails?.claimCustomerInfo
                : undefined,
            claimDocumentDate: claimDetails.isUkTeam
                ? claimDetails?.claimDocumentDate
                : undefined,
            claimDueDate: claimDetails.isUkTeam
                ? claimDetails?.claimDueDate
                : undefined,
        };

        if (validateFirst) {
            validateClaimFile(mapper).then(response => {
                if (isSuccessfulCall(response?.status)) {
                    onClaimUpload(false, file);
                    return;
                }
                // file is not valid)
                if (response?.response?.status === 409) {
                    setFileValidity(file);
                    return;
                }
            });
        } else {
            uploadClaim({ ...mapper, override }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    const copySteps = clonedeep(steps);
                    copySteps[currentStepIndex].isCompleted = true;
                    setSteps(copySteps);
                    setFileValidity(undefined);
                }
            });
        }
    };

    const handleComplete = (complete: Array<any>) => {
        onClaimUpload(true, {
            fileKey: complete[0].fileKey,
            fileName: complete[0].fileName,
        });
    };

    const handleRetry = (
        incomplete: Array<any>,
        retryCallback: (shouldRetry: boolean) => void,
    ) => {
        setFailedFiles(incomplete);
        (retryCallbackRef.current as any) = retryCallback;
    };

    const handleModalConfirm = (shouldRetry: boolean) => {
        if (!shouldRetry) {
            setFailedFiles([]);
            return;
        }

        if (retryCallbackRef.current) {
            retryCallbackRef.current(shouldRetry);
        }
    };

    const handleSubmit = () => {
        if (
            !claimDetails?.periodFrom ||
            !claimDetails?.periodTo ||
            !claimDetails?.file ||
            !claimDetails.contractPartner.accountId ||
            (claimDetails?.isUkTeam &&
                (claimDetails?.claimCustomerReference === "" ||
                    claimDetails?.claimCustomerInfo === "" ||
                    claimDetails?.claimDocumentDate === "" ||
                    claimDetails?.claimDueDate === ""))
        )
            return;

        scan(
            [{ file: claimDetails.file }],
            setFailedFiles,
            handleComplete,
            handleRetry,
        );
    };

    /**
     * Next Click handler
     */
    const onNextClick = () => {
        // reset and navigate to dashboard
        if (isFinished) {
            navigate("/process/process-details/");
            reset();
            return;
        }

        if (!canSubmit && !isFinished && steps[currentStepIndex].isPrepared) {
            stepRef.current.updateState(); // store the state in an atom
            const copySteps = clonedeep(steps);
            copySteps[currentStepIndex].isCompleted = true;
            setSteps(copySteps);

            return navigate(`/process/${steps[currentStepIndex + 1].id}/`);
        }

        // Submit
        if (canSubmit) {
            handleSubmit();
        }
    };

    /**
     * Click back handler
     */
    const onBackClick = () => {
        navigate(`/process/${steps[currentStepIndex - 1].id}/`);
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!failedFiles?.length}
                id={`dialog-confirm-scanning`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Following files failed malware scanning, you can retry
                        scanning these files or opt to proceed uploading only
                        those files that passed the malware scan
                        {failedFiles.map((file, idx) => (
                            <Typography key={idx} variant="body2" ml={1}>
                                {file?.fileName}
                            </Typography>
                        ))}
                    </Typography>
                }
                primaryButton={{
                    text: "Retry",
                    action: () => {
                        handleModalConfirm(true);
                        // setFailedFiles([]);
                    },
                    loading: scanning,
                }}
                secondaryButton={{
                    text: "Cancel claim file upload",
                    action: () => {
                        handleModalConfirm(false);
                    },
                    loading: false,
                }}
            />
            <Breadcrumbs
                id="reports-breadcrumbs"
                icon={<AutoModeIcon color="primary" />}
                title="Dashboard"
                location={location}
                subPage="Process"
            />

            <Dialog
                open={!!invalidFile}
                id={`claim-file-not-valid-dialog`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        The file contains periods different from the selected
                        claim period. Do you want to Upload the file?
                    </Typography>
                }
                primaryButton={{
                    text: "Continue",
                    action: () =>
                        onClaimUpload(
                            false,
                            invalidFile as {
                                fileName: string;
                                fileKey: string;
                            },
                            true,
                        ),
                    disabled: uploading,
                    loading: uploading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setFileValidity(undefined),
                }}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        steps={steps}
                        id="contract-stepper"
                        title={`Upload claim file`}
                        location={location}
                        disabled={uploading || !!isFinished}
                    />
                </FlowAside>

                <FlowBody>
                    <FlowContent>
                        {!!isFinished && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                height={1}
                                sx={{ wordBreak: "break-all" }}
                            >
                                <Typography variant="h2">
                                    {`(${claimDetails?.file?.name}) was successfully uploaded`}
                                </Typography>
                            </Box>
                        )}
                        {!isFinished &&
                            React.Children.map(
                                children as React.ReactElement,
                                (child: React.ReactElement) =>
                                    React.cloneElement(child, {
                                        location,
                                        currentStep,
                                        ref: stepRef,
                                        isFinished,
                                        disabled: uploading || validating,
                                        id: "start-claim-process",
                                    }),
                            )}
                    </FlowContent>

                    <FlowFooter>
                        <StepActions
                            id={`start-claim-process-step-actions`}
                            loading={
                                (!invalidFile && scanning) ||
                                uploading ||
                                validating
                            }
                            primaryButton={{
                                text: isFinished
                                    ? "Upload new file"
                                    : canSubmit
                                      ? "Upload"
                                      : "Next step",
                                action: onNextClick,
                                disabled:
                                    currentStepIndex !== undefined &&
                                    !steps[currentStepIndex]?.isPrepared,
                                isSubmitButton:
                                    currentStepIndex === steps?.length - 1 ||
                                    isFinished,
                            }}
                            secondaryButton={{
                                text: "Back",
                                action: onBackClick,
                                disabled: uploading,
                                hidden:
                                    isFinished ||
                                    (currentStepIndex !== undefined &&
                                        currentStepIndex === 0),
                            }}
                        />
                    </FlowFooter>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default ClaimProcessLayout;
